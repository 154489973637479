.valueStatement_container {
    padding-top: 50px;
    max-width: 900px;
}

.valueStatement_textContainer {
    padding-top: 15px;
}

.valueStatement_profilePic {
    width: 100%;
    max-width: 450px;
}