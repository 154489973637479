.resume_layoutContainer {
    height: 100%;
    padding-top: 25px;
}

.resume_profileContainer {
    height: 100%;    
}

.resume_profilePicContainer {
    position: relative;
    left: 0;
    top: calc(50% - 50px);
}

.resume_profileName {
    display: block;
}

.resume_profilePic {
    width: 100px;
    top: calc(50% - 50px);
    position: relative;
}

.resume_profileName span {
    display: table;
    margin: 0 auto;
    font-size: 25px;
    font-weight: bold;
}

.resume_headerRight {
    right: 0;
}

.resume_textRight {
    text-align: right;
}

.resume_employmentExperience {
    padding-top: 25px;
    padding-bottom: 15px;
}

.resume_innerProfileContainer {
    padding: 15px;
}